<template>
  <div>
    <MyTable
      :rows="reversedRows"
      :columns="columns"
      :is-loading="isLoading"
    >
      <template v-slot="{props}">
        <span v-if="props.column.field === 'date_added'">
          <span>
            {{ $helpers.formatDateTime(props.row.date_added) }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'actions'">
          <b-button
            variant="primary"
            class="w-100"
            @click="handleModal(props.row)"
          >
            <FeatherIcon icon="EditIcon" /> Edit
          </b-button>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </MyTable>
  </div>
</template>

<script>
import axios from '@axios'
import MyTable from '@/components/MyTable.vue'

export default {
  name: 'PriceAlgorithmLogs',
  components: {
    MyTable,
  },
  data() {
    const columns = [
      {
        field: 'date_added',
        label: 'Date Updated',
        thClass: 'text-left align-middle text-nowrap',
        tdClass: 'align-middle text-nowrap',
        sortable: true,
      },
      {
        field: 'location',
        label: 'Location',
        thClass: 'text-left align-middle text-nowrap',
        sortable: false,
      },
      {
        field: 'category',
        label: 'Category',
        thClass: 'text-left align-middle text-nowrap',
        sortable: false,
      },
      {
        field: 'revised_amount',
        label: 'Revised Price (RM)',
        thClass: 'text-left align-middle text-nowrap',
        sortable: false,
      },
      {
        field: 'updated_by',
        label: 'Updated By',
        thClass: 'text-left align-middle text-nowrap',
        sortable: false,
      },
      {
        field: 'remarks',
        label: 'Remarks',
        thClass: 'text-left align-middle text-nowrap',
        sortable: false,
      },
      {
        field: 'actions',
        label: 'Actions',
        thClass: 'text-left align-middle text-nowrap',
        tdClass: 'align-middle text-nowrap',
        sortable: false,
      },
    ]

    return {
      rows: [],
      columns,
      isLoading: false,
    }
  },
  computed: {
    reversedRows() {
      return [...this.rows].reverse()
    },
  },
  created() {
    this.handleDataFetching()
  },
  methods: {
    handleDataFetching() {
      this.fetchLogs()
    },
    async fetchLogs() {
      this.isLoading = true
      const response = await axios.get('price-logs')
      if (!response.status === 200) {
        this.$swal({
          title: 'Error',
          text: 'Something went wrong. Please try again.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn-primary',
          },
        })
        return
      }

      this.rows = response.data
      this.isLoading = false
    },
    async handleModal(item) {
      const { value: remarks } = await this.$swal({
        title: 'Remarks',
        text: 'Please enter remarks for the update',
        input: 'textarea',
        inputLabel: 'Enter remarks',
        inputPlaceholder: item.remarks,
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          header: 'mt-2',
          confirmButton: 'btn-primary',
          cancelButton: 'mr-2',
          input: 'mb-2',
          inputLabel: 'justify-content-start font-weight-bold',
          footer: 'mb-2',
        },
        /* eslint-disable-next-line */
        inputValidator: value => {
          if (!value) {
            return 'Remarks field is required. Write something!'
          }
        },
      })

      if (remarks) {
        this.isLoading = true
        const query = `${item.id}?remarks=${remarks}`
        const response = await axios.patch(`price-logs/${query}`)

        if (!response.status === 200) {
          this.$swal({
            icon: 'error',
            title: 'Error!',
            text: 'Something went wrong. Please try again.',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
          return
        }

        this.$swal({
          icon: 'success',
          title: 'Remarks Updated!',
          text: 'You have successfully updated the remarks of this record.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })

        this.isLoading = false
        this.handleDataFetching()
      }
    },
  },
}
</script>

<style>

</style>
